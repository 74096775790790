import React from 'react'
import { Link } from '@reach/router'
import styled from '@emotion/styled'
/* @jsx jsx */
// eslint-disable-next-line no-unused-vars
import { jsx, css } from '@emotion/core'

const Section = styled.section`
  position: relative;
  margin-bottom: 1rem;

  @media (min-width: 64em) {
    margin-top: 4rem;
  }
`

const linkStyle = css`
  color: #000;
  flex: 1;
  text-align: center;
  text-decoration: none;
  text-transform: uppercase;
  box-sizing: border-box;
  margin: 2rem 0;
  padding: 0 0.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 500;
  color: #275236;
  padding-top: 2rem;
  font-size: 1.5rem;
`

export default () => (
  <Section>
    <Link css={linkStyle} to="/ajanlatkeres">
      Ajánlatkérés ➤
    </Link>
  </Section>
)
