import React from 'react'
import { Link } from '@reach/router'
import { withSiteData } from 'react-static'
import styled from '@emotion/styled'
/* @jsx jsx */
// eslint-disable-next-line no-unused-vars
import { jsx, css } from '@emotion/core'
import Ajanlatkeres from '../containers/Ajanlatkeres'
import headerBg from '../images/header.jpg'
import roof from '../images/icons/roof.svg'
import roll from '../images/icons/roll.svg'
import corrugatedRoof from '../images/icons/corrugated-roof.svg'
import cleaning from '../images/icons/cleaning.svg'
import brick from '../images/icons/brick.svg'
import brush from '../images/icons/brush.svg'
import demolish from '../images/icons/demolish.svg'
import heating from '../images/icons/heating.svg'
import lightBulb from '../images/icons/light-bulb.svg'
import maintanance from '../images/icons/maintanance.svg'
import plastering from '../images/icons/plastering.svg'
import wall from '../images/icons/wall.svg'
import wood from '../images/icons/wood.svg'
import stairs from '../images/icons/stairs.svg'
import sketch from '../images/icons/sketch.svg'
import floor from '../images/icons/floor.svg'
import insulation from '../images/icons/insulation.svg'

const breakpoints = [64]

const mq = breakpoints.map((bp) => `@media (min-width: ${bp}em)`)

const Main = styled.main`
  padding: 1rem;
  line-height: 1.5rem;

  @media (min-width: 64em) {
    margin: 0 auto;
    max-width: 960px;
    padding-top: 90px;
  }
`

const Header = styled.div`
  position: relative;
  height: 480px;
  height: 100vh;
  height: calc(var(--vh, 1vh) * 100);
  background: url(${headerBg});
  background-position: bottom right;
  background-size: cover;
  filter: brightness(130%);
  display: flex;
  align-items: flex-start;
  justify-content: center;
  text-align: center;
  padding: 100px 1rem;
  font-size: 1.5rem;
  font-weight: 100;
  color: #000;

  span {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    text-align: left;
    padding: 0.5rem;
    color: #fff;
    font-size: 0.8rem;

    ${mq[0]} {
      font-size: 1rem;
    }
  }

  ${mq[0]} {
    height: 100vh;
    max-height: 1280px;
    font-size: 3rem;
    padding-top: 8rem;
  }
`

const Title = styled.h2`
  padding-top: 90px;
  margin-top: -80px;
  font-weight: 200;
  color: #275236;
`

const Section = styled.section`
  position: relative;
  margin-bottom: 1rem;

  // &:nth-of-type(2) {
  //   color: white;

  //   ${Title} {
  //     color: white;
  //   }

  //   &::before {
  //     content: '';
  //     display: block;
  //     position: absolute;
  //     top: 80px;
  //     bottom: 0;
  //     left: -1rem;
  //     right: -1rem;
  //     background: black;
  //     z-index: -1;
  //   }
  // }
`

const Row = styled.div`
  display: flex;
  flex-wrap: wrap;
`

const servicesLinkStyle = css`
  // display: flex;
  flex: 1 0 calc(30% - 1rem);
  height: 164px;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  color: initial;
  flex-direction: column;
  font-weight: 100;
  margin: 1rem 0.5rem;
  font-size: 0.8rem;

  span {
    width: 100%;
    display: block;
    text-align: center;
    margin-top: 0.5rem;
  }

  img {
    margin: 0 auto;
    display: block;
    width: 64px;
    height: 64px;
  }
`

export default () => (
  <div>
    <Header>
      A tető az életünk
      <span>A Szent István Bazilika tetőkarbantartója</span>
    </Header>
    <Main>
      <Section>
        <Title id="cegunk">Cégünk</Title>
        <p>
          Saját telephellyel, személygépkocsikkal és kisteherautókkal, valamint a kivitelező munkához szükséges,
          megfelelő szerszámokkal, eszközökkel, munkavédelmi felszerelésekkel rendelkezünk.
        </p>
        <p>
          Az emberi erőforrások biztosítása során mindig törekedünk arra, hogy megbízható, szakmunkás bizonyítvánnyal és
          megfelelő szakmai tudással, gyakorlattal rendelkező munkatársakat foglalkoztassunk.
        </p>
        <p>
          Cégünk elsősorban Budapesten fejti ki fő tevékenységét. Kivitelezésünk jó minőségét a főváros több kerületében
          elvégzett, pályázati úton elnyert és elkészült Önkormányzati referencia munkák igazolják. A V, VI, VII, VIII,
          IX, XIII, XX-XXIII. Kerületekben, számos referenciával rendelkezünk.
        </p>
      </Section>
      <Section>
        <Title id="szolgaltatasaink">Szolgáltatásaink</Title>
        <Row>
          <Link css={servicesLinkStyle} to="/tetoepites">
            <img src={roof} alt="icon" />
            <span>Tetőépítés, felújítás, karbantartás, gyorsszolgálat</span>
          </Link>
          <Link css={servicesLinkStyle} to="/tetoszigeteles">
            <img src={roll} alt="icon" />
            <span>Tetőszigetelés</span>
          </Link>
          <Link css={servicesLinkStyle} to="/hoszigeteles">
            <img src={insulation} alt="icon" />
            <span>Hőszigetelés</span>
          </Link>
          <Link css={servicesLinkStyle} to="/badogos-munkak">
            <img src={corrugatedRoof} alt="icon" />
            <span>Bádogos munkák</span>
          </Link>
          <Link css={servicesLinkStyle} to="/generalkivitelezes">
            <img src={maintanance} alt="icon" />
            <span>Generálkivitelezés</span>
          </Link>
          <Link css={servicesLinkStyle} to="/bontasi-munkalatok">
            <img src={demolish} alt="icon" />
            <span>Bontási munkálatok</span>
          </Link>
          <Link css={servicesLinkStyle} to="/gipszkartonozas">
            <img src={plastering} alt="icon" />
            <span>Gipszkartonozás</span>
          </Link>
          <Link css={servicesLinkStyle} to="/burkolas">
            <img src={floor} alt="icon" />
            <span>Burkolás</span>
          </Link>
          <Link css={servicesLinkStyle} to="/festes">
            <img src={brush} alt="icon" />
            <span>Külső-belső festés, mázolás, tapétázás</span>
          </Link>
          <Link css={servicesLinkStyle} to="/komuves-munkak">
            <img src={brick} alt="icon" />
            <span>Kőműves munkák</span>
          </Link>
          <Link css={servicesLinkStyle} to="/homlokzat-tisztitas">
            <img src={cleaning} alt="icon" />
            <span>Homlokzat tisztítás</span>
          </Link>
          <Link css={servicesLinkStyle} to="/villanyszereles">
            <img src={lightBulb} alt="icon" />
            <span>Villanyszerelés</span>
          </Link>
          <Link css={servicesLinkStyle} to="/futesszereles">
            <img src={heating} alt="icon" />
            <span>Víz-, gáz-, fűtésszerelés</span>
          </Link>
          <Link css={servicesLinkStyle} to="/fakivagas">
            <img src={wood} alt="icon" />
            <span>Fakivágás, gallyazás, veszélytelenítés</span>
          </Link>
          <Link css={servicesLinkStyle} to="/ko-es-gipszmunkak">
            <img src={wall} alt="icon" />
            <span>Kő és gipszmunkák</span>
          </Link>
          <Link css={servicesLinkStyle} to="/lepcso-kivitelezes">
            <img src={stairs} alt="icon" />
            <span>Lépcső kivitelezés</span>
          </Link>
          <Link css={servicesLinkStyle} to="/muszaki-vezetes">
            <img src={sketch} alt="icon" />
            <span>Felelős műszaki vezetés</span>
          </Link>
        </Row>
      </Section>
      <Ajanlatkeres />
    </Main>
  </div>
)
